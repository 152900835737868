import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Close, CreateNewFolder, PersonRemove, Search } from '@mui/icons-material';
import { Alert, Button, Chip, CircularProgress, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, Stack, Step, StepLabel, Stepper, TextField, Avatar, List, OutlinedInput, Checkbox, FormControlLabel, Autocomplete, Skeleton } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { addExamVetCase, addUserVetCase, addVetCase } from 'src/redux/slices/examen';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getOrganisationUsers, inviteUser, isUserExist } from 'src/redux/slices/user';
import { debounce, get, isArray, isEmpty, uniqBy } from 'lodash';
import { useState } from 'react';
import { formatDate, getOutils, getOutilsColor } from 'src/utils/dashboard';
import { useSnackbar } from 'notistack';
import { getTeam } from 'src/redux/slices/team';
import { getHorse, getHorseExams, getHorses } from 'src/redux/slices/horse';
import useLocales from 'src/hooks/useLocales';
import { dateComparator, dateComparatorDesc } from 'src/utils/examDashboard';
import * as yup from 'yup';
import '../exam/style.css'
import { ExamExtractor } from 'src/utils/ExamExtractor';
import Iconify from '../Iconify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1,
    height: 'auto',
    overflowY: 'scroll'
};

//Component for step 1 "Créer un dossier"
function AddVetCase({ loading, teamChanged, data, submitted, horses, teams, horsesUpdated, horsesLoading }) {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { userInOrganisation } = useSelector((state) => state.user);
    const [horseName, setHorseName] = useState(null)
    const [searchedHorses, setSearchedHorses] = useState([])
    let org_id = get(userInOrganisation, 'organisation.id', null)
    let user_org_id = get(userInOrganisation, 'id', null)
    let id = get(userInOrganisation, 'restricted', false) ? user_org_id : org_id



    const vetSchema = Yup.object().shape({
        horse: Yup.string()
            .required(translate('yup.horse.required')),
        // team: Yup.string().required(translate('yup.team.required')),
        case_number: Yup.string().required(translate('yup.folder.required')),
    });

    const defaultValues = data;

    const methods = useForm({
        resolver: yupResolver(vetSchema),
        defaultValues,
    });

    const onSubmit = data => submitted(data)

    const getHorseMutation = useMutation((name) => dispatch(getHorses(id, name)))
    const getHorseByName = debounce((name) => {
        setHorseName(name)
        if (!name) return
        getHorseMutation.mutateAsync(name)
            .then(result => {
                let data = get(result, 'data.["hydra:member"]', [])
                let __horses = uniqBy([...data, ...horses], '@id')
                horsesUpdated(__horses)
            }).catch(err => {

            })
    }, 500)

    const mappedHorses = useMemo(() => {
        return !isEmpty(horses) ? [...horses].map(horse => ({ id: get(horse, '@id'), label: horse?.name ?? '' })) : []
    }, [horses])

    const getHorseById = (id) => {
        let found = horses.find(horse => get(horse, '@id') === id)
        return found ? { id: get(found, '@id'), label: found?.name ?? '' } : null
    }

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = methods;
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} marginTop={3}>
                <Grid item md={12} sm={12}>
                    <FormControl fullWidth error={errors?.case_number}>
                        <TextField
                            {...register("case_number")}
                            error={errors?.case_number}
                            name='case_number' placeholder={translate('grid.folderNumber')} label={translate('grid.folderNumber')}></TextField>
                        <FormHelperText>{errors?.case_number?.message}</FormHelperText>

                    </FormControl>
                </Grid>
                <Grid item md={12} sm={12}>
                    {
                        horsesLoading ?
                            <Skeleton sx={{ borderRadius: 1 }} width={'100%'} variant="rectangular" height={60} ></Skeleton>
                            : <FormControl fullWidth error={errors?.horse}>
                                <Autocomplete
                                    disablePortal
                                    loading={getHorseMutation.isLoading}
                                    defaultValue={getHorseById(data?.horse)}
                                    error={errors?.horse}
                                    {...register("horse")}
                                    onChange={(e, newValue) => {
                                        setValue('horse', newValue?.id, { shouldValidate: true })
                                    }} // Using setValue
                                    id="combo-box-demo"
                                    options={mappedHorses}
                                    renderInput={(params) => <TextField
                                        error={errors?.horse} onChange={e => getHorseByName(e.target.value)}
                                        {...params} placeholder={translate('grid.horseName')} label={translate('grid.horseName')} />}
                                />
                                <FormHelperText>{errors?.horse?.message}</FormHelperText>
                            </FormControl>
                    }

                </Grid>
                <Grid item md={12} sm={12}>
                    <FormControl fullWidth>
                        <TextField {...register("vet_name")} name='vet_name' label={translate('grid.vet')} placeholder={translate('grid.vet')}></TextField>
                    </FormControl>
                </Grid>
                <Grid item md={12} sm={12}>
                    <FormControl fullWidth>
                        <TextField {...register("reason")} name='reason' label={translate('grid.reason')} placeholder={translate('grid.reason')}></TextField>
                    </FormControl>
                </Grid>
                <Grid item md={12} sm={12}>
                    <FormControl fullWidth>
                        <TextField {...register("notes")} name='notes' label={translate('grid.note')} placeholder={translate('grid.note')}></TextField>
                    </FormControl>
                </Grid>
                <Grid item md={12} sm={12} display='flex' justifyContent='end' gap={2} flexDirection='row' alignSelf={'end'} position={'sticky'} bottom={0}>
                    <LoadingButton loading={loading} type='submit' variant='contained'>{translate('button.continue')}</LoadingButton>
                </Grid>
            </Grid>
        </form>
    )
}

//Component for step 2 "Ajouter un utilisateur"
AddUserVetCase.defaultProps = {
    selected: []
}
function AddUserVetCase({ selected, users, back, submit, team, byMail, teams, teamChanged, setTeamValue, selectedTeam, teamLoading }) {
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")
    const [selectedUser, setSelected] = useState("")
    const [user, setUser] = useState([])
    const [fromList, setFromList] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const [foundUsers, setFoundUsers] = useState([])
    const [addedByMail, setAddedByMail] = useState([])
    const { translate } = useLocales()
    const inviteMutation = useMutation(() => inviteUser(email))


    useEffect(() => {
        if (!selectedTeam) {
            const mappedTeams = teams.map(e => e?.team)
            console.log({ teams });

            let onlyMeTeam = mappedTeams.find(team => team?.is_own_team)
            if (onlyMeTeam) {
                setTeamValue(onlyMeTeam['@id'])
            }

        }
    }, [selectedTeam])

    const _team = teams.find(e => e?.team?.['@id'] === selectedTeam)?.team


    let schema = yup.object().shape({
        email: yup.string().email(),
    });

    const checkEmailMutation = useMutation(() => isUserExist(email), {
        onSuccess: ({ data }) => {
            let users = get(data, 'hydra:member')
            setFoundUsers(users)
            setFromList(false)
            if (users.length > 0) {
                setSelected(users[0])
                addUser(users[0])
                setEmail('')
                enqueueSnackbar(translate('snackbar.caseUserCreateSuccess'), {
                    variant: 'success'
                })
            } else {
                inviteMutation.mutateAsync().then(({ data }) => {
                    addUser(data)
                    setEmail('')
                    enqueueSnackbar(translate('snackbar.caseUserCreateSuccess'), {
                        variant: 'success'
                    })
                })
            }
        }
    })


    useEffect(() => {
        if (!isEmpty(selected)) {
            setUser(selected)
        }
    }, [selected])

    useEffect(() => {
        if (!isEmpty(byMail)) {
            setAddedByMail(byMail)
        }
    }, [byMail])


    const getName = (user) => {
        if (user?.lastname) {
            return user?.lastname + ' ' + user?.firstname
        } else {
            return user?.email
        }
    }

    const addUser = (user) => {
        let byMail = [...addedByMail, user]
        setAddedByMail(uniqBy(byMail, 'id'))
    }


    return (
        <Grid container spacing={2} marginTop={3}>
            <Grid item md={12}>

                <FormControl fullWidth >
                    <InputLabel id="demo-simple-select-label">{translate('grid.userFullname')}</InputLabel>
                    <Select
                        disabled={teamLoading}
                        name='team'
                        defaultValue={selectedTeam}
                        onChange={(e) => {
                            setTeamValue(e.target.value)
                            teamChanged(get(teams?.find(i => get(i, 'team.@id') == e.target.value), 'team.id'))
                        }} // Using setValue
                        label="Team"
                    >
                        {teams?.map(el => (
                            <MenuItem key={el?.team?.id} value={get(el, 'team.@id')}>{el?.team?.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            {
                teamLoading ? <Box alignItems={'center'} width={'100%'} display={'flex'} justifyContent={'center'} my={2}>
                    <CircularProgress></CircularProgress>
                </Box> : <>
                    <List sx={{ width: '100%', maxHeight: 300, bgcolor: 'background.paper' }}>
                        <Divider>
                            <Typography variant='caption' color='grey'>{_team?.name ?? translate('nav.team')}</Typography>
                        </Divider>
                        <Box sx={{ position: 'relative', overflowY: 'scroll', maxHeight: '200px !important' }} >
                            {team.map(({ user }) => (
                                <React.Fragment key={user.id}>
                                    <ListItem alignItems="center" >
                                        <ListItemAvatar>
                                            <Avatar src="/static/images/avatar/1.jpg" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={getName(user)}
                                        />
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </Box>


                    </List>
                    {
                        !isEmpty(users) &&
                        <>
                            <Divider></Divider>
                            <Grid item md={12} sm={12}>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{translate('general.members')}</InputLabel>
                                    <Select
                                        label="Utilisateur"
                                        value={user}
                                        defaultValue={[]}
                                        multiple
                                        onChange={(e) => {
                                            setUser(e.target.value)
                                        }}
                                        endAdornment={user && <IconButton onClick={() => setUser([])}>
                                            <Close fontSize='small'></Close>
                                        </IconButton>
                                        }
                                    >
                                        {
                                            users?.map(user => (
                                                <MenuItem value={user}>{getName(user)}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    }


                    <Grid item md={12} sm={12}>
                        <Divider>
                            <Typography variant='body2' color='gray' marginY={1}>{translate('general.byMail')}</Typography>
                        </Divider>
                        <Box sx={{ position: 'relative', overflowY: 'scroll', maxHeight: '100px !important' }}>
                            {addedByMail.map((user) => (
                                <React.Fragment key={user.id}>
                                    <ListItem secondaryAction={
                                        <IconButton color='error' onClick={() => {
                                            setAddedByMail(prev => [...prev].filter(el => el.id !== user.id))
                                        }}>
                                            <PersonRemove></PersonRemove>
                                        </IconButton>
                                    } alignItems="center" disableGutters >
                                        <ListItemAvatar>
                                            <Avatar src="/static/images/avatar/1.jpg" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={getName(user)}
                                        />
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </Box>

                        <OutlinedInput
                            placeholder={translate('auth.email')}
                            fullWidth
                            value={email}
                            error={error}
                            disabled={checkEmailMutation.isLoading || inviteMutation.isLoading}
                            onChange={e => {
                                checkEmailMutation.reset()
                                setSelected('')
                                setError('')
                                setEmail(e.target.value.trim())
                            }}
                            onKeyPress={(e) => {

                                e.key === 'Enter' && schema.isValid({ email }).then(valid => {
                                    valid ? checkEmailMutation.mutate() : setError(translate('yup.email.valid'))
                                })
                            }}
                            endAdornment={<>
                                <IconButton onClick={() => {
                                    schema.isValid({ email }).then(valid => {
                                        valid ? checkEmailMutation.mutate() : setError(translate('yup.email.valid'))
                                    })
                                }} disabled={!email}>
                                    <Search />
                                </IconButton></>}
                        >
                        </OutlinedInput>
                        <Typography color='red' variant='caption'>{error}</Typography>
                    </Grid>
                    <Grid item md={12} sm={12} display='flex' justifyContent='space-between' gap={2} flexDirection='row'>
                        <Button onClick={back} variant='text'>{translate('nav.back')}</Button>
                        <Button onClick={() => submit(user, addedByMail)} variant='contained'>{translate('button.continue')}</Button>
                    </Grid>
                </>
            }

        </Grid>
    )
}

//Component for step 3 "Ajouter un Examen"
AddExamVetCase.defaultProps = {
    selected: []
}
function AddExamVetCase({ handleChange, loading, selected, back, submit, examens, dataLoad, items }) {
    const [exam, setExam] = useState([])
    const [exams, setExams] = useState([])
    const [autoAdd, setAutoAdd] = useState(false)

    const { translate } = useLocales()

    useEffect(() => {
        if (!isEmpty(selected)) {
            setExam(selected)
        }
    }, [selected])

    useEffect(() => {
        let exams = get(examens, 'data.hydra:member', [])
        exams = !isEmpty(exams) ? exams.sort((a, b) => dateComparatorDesc(
            ExamExtractor.getLastExam(a),
            ExamExtractor.getLastExam(b),
        )
        ) : []

        setExams(exams)
    }, [examens])


    useEffect(() => {
        handleChange(exam)
    }, [exam])

    const renderExam = (ids) => {
        let founds = exams.filter(exam => ids.includes(get(exam, '@id')))
        return (
            <>
                {founds.map((f, i) => (
                    <>
                        <Chip sx={{ backgroundColor: getOutilsColor(get(f, '@type')).background, color: getOutilsColor(get(f, '@type')).text, mr: 1 }} size='small' label={getOutils(get(f, '@type'))} />
                        <Typography variant='caption'>{ExamExtractor.getLastExam(f)} {i !== founds.length - 1 ? ' - ' : ''}</Typography>
                    </>
                ))}

            </>
        )
    }

    if (dataLoad) {
        return (
            <Box sx={{ marginTop: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Grid container spacing={2} marginTop={3}>
            <Grid item md={12} sm={12}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{translate('general.exams')}</InputLabel>
                    <Select
                        label="Examens"
                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                        multiple
                        defaultValue={[]}
                        value={exam}
                        onChange={(e) => setExam(e.target.value)}
                        endAdornment={exam && <IconButton onClick={() => setExam([])}>
                            <Close fontSize='small'></Close>
                        </IconButton>
                        }
                        renderValue={renderExam}
                    >
                        {exams?.map(examen =>
                            <MenuItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} key={examen?.external_id} value={get(examen, '@id')}>

                                <Chip sx={{ backgroundColor: getOutilsColor(get(examen, '@type')).background, color: getOutilsColor(get(examen, '@type')).text }} size='small' label={getOutils(get(examen, '@type'))} />
                                <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
                                    <Typography variant='caption'>{ExamExtractor.getLastExam(examen)}</Typography>
                                    <Typography fontWeight={500} variant='caption'>{ExamExtractor.getDescription(examen)}</Typography>
                                </Box>
                            </MenuItem>)}

                    </Select>
                </FormControl>
                <Box display={'flex'} flexDirection={'column'}>
                    <FormControlLabel control={<Checkbox onChange={e => {
                        e.target.checked ? setExam(exams.map(exam => get(exam, '@id'))) : setExam([])
                    }}></Checkbox>} label={translate('selectAll')}></FormControlLabel>
                    <FormControlLabel control={<Checkbox value={autoAdd} onChange={e => setAutoAdd(e.target.checked)}></Checkbox>} label={translate('autoAddExam')}></FormControlLabel>
                </Box>

            </Grid>
            <Grid item md={12} sm={12} display='flex' justifyContent='space-between' gap={2} flexDirection='row'>
                <Button disabled={loading} onClick={back} variant='text'>{translate('nav.back')}</Button>
                <LoadingButton loading={loading} onClick={() => submit(exam, autoAdd)} variant='contained'>{translate('nav.terminer')}</LoadingButton>
            </Grid>
        </Grid>
    )
}

//Principal Modal
function AddVetCaseModal({ onRequestRefresh, open, handleClose, teams, horses, examens, items, defaultHorse, refreshLink, horsesLoading }) {

    const { userInOrganisation, user } = useSelector(state => state.user)
    const [_horses, setHorses] = useState([])
    const query = useQueryClient()
    const organisation = userInOrganisation?.organisation || {}

    const [users, setUsers] = useState([])

    useEffect(() => {
        let temp = isArray(horses) ? [...horses].sort((a, b) => a?.name?.localeCompare(b?.name)) : horses
        setHorses(temp)
    }, [horses])



    const selectedExamIds = useMemo(() => {
        return [...items].map(e => get(e, '@id'))
    }, [items])

    const { translate } = useLocales()
    const [teamLoading, setTeamLoading] = useState(false)

    const [activeStep, setActiveStep] = React.useState(0);

    const [vetUserData, setVetUserData] = useState([])

    const [vetExamData, setVetExamData] = useState(selectedExamIds)
    const [addedByMail, setAddedByMail] = useState([])

    const [savedVetId, setSavedVetId] = useState(null)


    const [teamsUser, setTeamsUser] = useState([])

    useEffect(() => {
        if (defaultHorse) {
            let isHorseExist = _horses.find(e => get(e, '@id') === get(defaultHorse, '@id'))
            if (!isHorseExist) {
                setHorses(prev => [...prev, defaultHorse])
            }
            setVetData(prev => ({ ...prev, horse: get(defaultHorse, '@id') }))
        }
    }, [defaultHorse, _horses])


    const [vetData, setVetData] = React.useState({
        horse: "",
        team: "",
        reason: "",
        case_number: "",
        notes: "",
        vet_name: "",
    });

    const reset = () => {
        setVetData({
            horse: "",
            team: "",
            reason: "",
            case_number: "",
            notes: "",
            vet_name: '',
        })
        setVetUserData([])
        setAddedByMail([])
        setActiveStep(0)
    }

    useEffect(() => {
        if (isEmpty(teams)) return
        let mapped_teams = teams.map(team => team?.team)

        let own_team = mapped_teams.find(e => e?.is_own_team)

        if (own_team) {
            setVetData(prev => ({ ...prev, team: get(own_team, '@id', '') }))
        }

    }, [teams])


    //get Exams query params

    const getOrgQueryParam = useMemo(() => {
        if (userInOrganisation?.restricted) {
            return [`organisation=${userInOrganisation?.id}`]
        } else {
            return [`user_in_organisation=${userInOrganisation?.id}`]
        }
    }, [userInOrganisation])

    //Init mutations
    const vetMutation = useMutation((data) => addVetCase(data))
    const userMutation = useMutation((vet_case) => addUserVetCase({ vet_case, users: vetUserData }))
    const examMutation = useMutation((vet_case) => addExamVetCase({ vet_case, exams: vetExamData }))
    const getHorseExamMutation = useMutation(() => {
        let horse = _horses?.find(el => el['@id'] === vetData.horse)
        if (horse) return getHorseExams(horse?.external_id, undefined, getOrgQueryParam)
    }
    )

    const { enqueueSnackbar } = useSnackbar();

    const getSelectedUsersIds = () => {
        return [...addedByMail, ...vetUserData].map(user => get(user, '@id'))
    }

    const saveVetCase = (autoAddExam) => {

        vetMutation.mutateAsync({
            ...vetData,
            users: getSelectedUsersIds(),
            exams: vetExamData,
            auto_add_future_horse_exams: autoAddExam
        }).then(async (res) => {
            if (vetExamData.length > 0) {
                query.invalidateQueries(['exams', get(userInOrganisation, 'organisation.id', null)])
            }
            let id = get(res, 'data.@id')
            setSavedVetId(id)
            enqueueSnackbar(translate('snackbar.caseCreateSuccess'), { variant: "success" });
            onRequestRefresh(res?.data)

            //check if selected exams is same as selected
            if (vetExamData.includes(get(items, '@id'))) {
                refreshLink(res?.data?.id)
            }

            vetMutation.reset()
            reset()
            handleClose()
        })



    }


    //getTeam
    async function geTteam(id) {
        try {
            setTeamLoading(true)
            //Get Team
            let response = await getTeam(id)


            //Getting user ids
            let ids = get(response, 'data.user_in_teams', []).map(item => item?.user).map(user => user?.id)

            setTeamsUser(get(response, 'data.user_in_teams', []))

            //Getting users
            let users = await getOrganisationUsers(organisation?.id)

            //Filter users
            let temp = users?.filter(user => !ids.includes(user?.id))

            setUsers(temp)
        } catch (err) {
            console.log(err);
            setUsers([])
        } finally {
            setTeamLoading(false)
        }
    }


    useEffect(() => {
        open && getHorseExamMutation.mutate()
    }, [vetData.horse,open])

    //Stepper steps
    const steps = [translate('addCase.createCase'), translate('addCase.addUser'), translate('addCase.addExam')];

    return (
        <Modal
            open={open}

            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {/* Header */}
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Box display='flex' gap={1} alignItems='center'>
                        <Iconify width={21} height={21} icon='hugeicons:folder-add'></Iconify>
                        <Typography fontWeight={600} variant='h6'>{translate('addCase.createNewCase')}</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Close fontSize='small' />
                    </IconButton>
                </Box>

                {/* Success Section */}
                <Stack spacing={1} marginBottom={1}>
                    {vetMutation.isSuccess && <Alert severity="success">{translate('snackbar.caseCreateSuccess')}</Alert>}
                    {userMutation.isSuccess && <Alert severity="success">{translate('snackbar.caseUserCreateSuccess')}</Alert>}
                    {examMutation.isSuccess && <Alert severity="success">{translate('snackbar.caseExamCreateSuccess')}</Alert>}
                </Stack>


                {/* Error Section */}
                <Stack spacing={1}>
                    {vetMutation.isError && <Alert severity="error" action={
                        <LoadingButton color='error' loading={vetMutation.isLoading} onClick={() => vetMutation.mutate()} size="small">
                            {translate('button.retry')}
                        </LoadingButton>
                    }>{translate('snackbar.caseCreateError')}</Alert>}
                    {userMutation.isError && <Alert action={
                        <LoadingButton color='error' loading={userMutation.isLoading} onClick={() => userMutation.mutate(savedVetId)} size="small">
                            {translate('button.retry')}
                        </LoadingButton>
                    } severity="error">{translate('snackbar.caseUserCreateError')}</Alert>}
                    {examMutation.isError && <Alert action={
                        <LoadingButton color='error' loading={examMutation.isLoading} onClick={() => examMutation.mutate(savedVetId)} size="small">
                            {translate('button.retry')}
                        </LoadingButton>
                    } severity="error">{translate('snackbar.caseExamCreateError')}</Alert>}
                </Stack>


                {/* Content */}
                <Box sx={{ width: '100%' }} marginTop={3}>
                    <Stepper alternativeLabel activeStep={activeStep} >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === 0 && <AddVetCase
                        horsesLoading={horsesLoading}
                        horsesUpdated={horses => setHorses(horses)} loading={teamLoading} teamChanged={(id) => geTteam(id)} data={vetData} horses={_horses} teams={teams} submitted={(data) => {
                            setVetData(data)
                            setActiveStep(1)
                        }} />}
                    {activeStep === 1 && <AddUserVetCase teamLoading={teamLoading} setTeamValue={team => setVetData(prev => ({ ...prev, team }))} byMail={addedByMail} selected={vetUserData} teamChanged={(id) => geTteam(id)} team={teamsUser} teams={teams} users={users} back={() => setActiveStep(0)} selectedTeam={vetData.team} submit={(data, mails) => {
                        setVetUserData(data)
                        setAddedByMail(mails)
                        setActiveStep(2)
                    }} />}
                    {activeStep === 2 && <AddExamVetCase items={items} handleChange={data => setVetExamData(data)} selected={vetExamData} examens={getHorseExamMutation.data} loading={vetMutation.isLoading || userMutation.isLoading || examMutation.isLoading} dataLoad={getHorseExamMutation.isLoading} back={() => setActiveStep(1)} submit={(data, autoAdd) => {
                        saveVetCase(autoAdd)
                    }} />}
                </Box>


                {/* End of Content */}
            </Box>
        </Modal>
    )
}


//Default modal props value
AddVetCaseModal.defaultProps = {
    teams: [],
    horses: [],
    open: false,
    items: []
}
export default AddVetCaseModal


